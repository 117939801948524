import React from 'react'
import Navbar1 from '../components/Navbar1'
import Carousel1 from '../components/Carousel'
import Hero1 from '../components/Hero1'
import WhyUs from '../components/WhyUs'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <> 
    
        <Navbar1/>
        <Carousel1/>
        <WhyUs/>
        <Hero1/>
        <Footer/>

    </>
  )
}

export default Home;