import React from "react";

const Notfound = () => {
  return (
    <>
      <div className="flex justify-center align-middle items-center my-auto py-auto h-screen font-bold text-3xl" >
        <h1>Not Found page!</h1>
      </div>
    </>
  );
};

export default Notfound;
