import React from "react";
import { Link } from "react-router-dom";

const Hero1 = () => {
  return (
    <>
      <div className="px-1 sm:px-10 py-10 bg-zinc-300">
        <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 -px-20">
              <img
                className="object-cover object-center rounded sm:h-[26rem] h-[11rem] w-[49rem] sm:w-[35rem] "
                alt="hero"
                src="https://i.postimg.cc/PfCpR7r8/Whats-App-Image-2023-11-06-at-9-28-10-PM.jpg"
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-5xl text-4xl mb-4 font-medium text-gray-900">
                {" "}
                creativity meets
                <br className="hidden lg:inline-block" /> apparel!
              </h1>
              <p className="mb-8 leading-relaxed text-lg">
                {" "}
                We are your go-to destination for personalized caps and t-shirts
                that allow you to express your unique style and make a
                statement. <br /> SUPPLIER OF T-Shirt , Bags , Pen , Lyard , and
                Corporate Gift items, etc.
                <br /> SINCE : 2023{" "}
              </p>
              <div className="flex justify-center">
                <button className="inline-flex text-white bg-blue-500 border-0 py-1 px-2 sm:py-2 sm:px-5 focus:outline-none hover:bg-blue-600 rounded text-lg">
                  {" "}
                  <Link to="/about"> About Us </Link>
                </button>
                <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-1 px-2 sm:py-2 sm:px-5 focus:outline-none hover:bg-gray-200 rounded text-lg">
                  {" "}
                  <Link to="/products ">View Products </Link>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Hero1;
