import React from 'react'
import Navbar1 from '../components/Navbar1'
import Footer from '../components/Footer'
import Products1 from '../components/Products1'

const Products = () => {
  return (
    <>
        <Navbar1/>
        <Products1/>
        <Footer/>
    </>
  )
}

export default Products