import React from 'react'
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import Aboutus from './pages/Aboutus';
import Products from './pages/Products';
import Contactus from './pages/Contactus';
import Notfound from './pages/Notfound';

const App = () => {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
          <Route   path="/about" element={<Aboutus />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/products" element={<Products />} />
          <Route path="*" element={<Notfound />} />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
    </>
  )
}

export default App