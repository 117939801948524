import React from "react";

const Aboutus1 = () => {
  return (
    <>
      <section className="text-gray-700 bg-zinc-300 body-font">
        <div className="container px-5 py-14 mx-auto flex flex-col">
          <div className="lg:w-[65rem] mx-auto">
            <div className="rounded-lg h-72 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src="https://i.postimg.cc/mkBTc6zL/kaycee-ingram-4-EWh-ZW7q1hg-unsplash.jpg"
              />
            </div>
            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                {/* <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-10 h-10"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div> */}
                <div className="flex flex-col items-center text-center justify-center">
                  <h2 className="font-bold title-font mt-4 text-gray-900 text-lg">
                    NEW ERA 
                    <p className="font-semibold">Caps & Hat Manufacturer</p>
                  </h2>
                  <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                  <p className="text-base">
                  At NewEra, we are your one-stop destination for wholesale customizable caps, hats,t-shirts, Bags, Pen, Lyard, and Corporate Gift items, etc. We understand that every brand, event, or organization is unique, and we're here to help you stand out with personalized apparel that represents your style and message.
                  </p>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-8 sm:py-8  sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-24 text-center sm:text-left">
                <p className="leading-relaxed text-lg mb-4">
                <p>SUPPLIER OF -T Shirts , Bags , Pen , Lyard , and Corporate Gift items, etc.</p>
                <p>BUSINESS RELATIONSHIP: Ajax Engineering Pvt Ltd, BEML , Google , Usha Armour Safety,etc.</p>
                SINCE: 2023
                </p>
                <a className="text-indigo-500 inline-flex items-center">
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="4"
                    className="w-6 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus1;
