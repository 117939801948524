import React from "react";
import { Link } from "react-router-dom";

const WhyUs = () => {
  return (
    <>
    <div>
      <section className="text-gray-600 body-font py-3 bg-zinc-300  ">
        <div className="container px-5 mt-[12rem] mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-xs text-blue-500 tracking-widest font-medium title-font mb-1">
              ROOF PARTY POLAROID
            </h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
             WHY CHOOSE US?
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Whether you're looking to promote your business, create unique merchandise, or mark a special occasion,<br/> NEW ERA is here to bring your vision to life.
            </p>
          </div>
          <div className="flex flex-wrap justify-center gap-9">
            <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
              Endless Customization :
              </h2>
              <p className="leading-relaxed text-base mb-4">
              From embroidery and screen printing to a variety of colors and styles, we provide endless customization options. Your imagination is the only limit!
              </p>
              <a className="text-blue-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
              Quality and Durability : 
              </h2>
              <p className="leading-relaxed text-base mb-4">
              We source the finest materials to ensure that your customized caps and t-shirts are not only stylish but also durable and comfortable to wear.
              </p>
              <a className="text-blue-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
              Bulk Orders Welcome : 
              </h2>
              <p className="leading-relaxed text-base mb-4">
              Whether you're a small group or a large organization, we can handle orders of any size. We offer competitive pricing for bulk orders.
              </p>
              <a className="text-blue-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            {/* <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Melanchole
              </h2>
              <p className="leading-relaxed text-base mb-4">
                Fingerstache flexitarian street art 8-bit waistcoat. Distillery
                hexagon disrupt edison bulbche.
              </p>
              <a className="text-blue-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div> */}
          </div>
          <button className="flex mx-auto mt-12 text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg">
           <Link to="/contact"> Contact Us </Link> 
          </button>
        </div>
      </section>
      </div>
    </>
  );
};

export default WhyUs;
