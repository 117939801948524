import React from "react";
import Navbar1 from "../components/Navbar1";
import Footer from "../components/Footer";
import { useEffect } from "react";
import Contact1 from "../components/Contact1";
import Contact2 from "../components/Contact2";

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar1 />
      <Contact1/>
      <Contact2/>
      <Footer />
    </>
  );
};

export default Contactus;
