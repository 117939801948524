import React from "react";

const Aboutus2 = () => {
  return (
    <>
      <section className="text-gray-700 bg-zinc-300 body-font sm:px-12 ">
        <div className="container px-5 py-20 mx-auto">
          <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
              ABOUT US
            </h1>
            <p className="lg:w-1/2 w-full leading-relaxed text-gray-700">
            Discover the endless possibilities with NewEra and start your wholesale customization journey today!
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div> */}
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Wholesale Expertise: 
                </h2>
                <p className="leading-relaxed text-base">
                With years of experience in the wholesale industry, we understand the importance of competitive pricing and efficient service. Our bulk order capabilities ensure that you get the best value for your investment. NewEra is your trusted partner for large-scale orders.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="6" cy="6" r="3"></circle>
                    <circle cx="6" cy="18" r="3"></circle>
                    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                  </svg>
                </div> */}
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Quality Meets Versatility: 
                </h2>
                <p className="leading-relaxed text-base">
                Our products are carefully curated to ensure premium quality and comfort. We offer a diverse range of caps, hats, and t-shirts suitable for various occasions, styles, and purposes. 
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div> */}
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Endless Customization: 
                </h2>
                <p className="leading-relaxed text-base">
                We believe in the power of customization. Our state-of-the-art customization options, including embroidery, screen printing, and a wide array of colors and styles, allow you to create a unique look that represents your brand or event. 
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                  </svg>
                </div> */}
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Customer-Centric Approach: 
                </h2>
                <p className="leading-relaxed text-base">
                At NewEra, exceptional customer service is our hallmark. Our dedicated team is ready to guide you through the customization process, answer your questions, and ensure your satisfaction at every step. 
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                  </svg>
                </div> */}
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Fast Turnaround: 
                </h2>
                <p className="leading-relaxed text-base">
                We understand the need for prompt delivery. NewEra has streamlined production processes to ensure that you receive your customized items quickly, without compromising on quality.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 rounded-lg">
                {/* <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                  </svg>
                </div> */}
                <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                 Bond With US:
                </h2>
                <p className="leading-relaxed text-base">
                  At NewEra, we take pride in being the industry leader for wholesale customizable caps, hats, and t-shirts. We're not just a supplier; we're your creative partner in fashion and branding. Here's what sets us apart:
                </p>
              </div>
            </div>
          </div>
          {/* <button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Button
          </button> */}
        </div>
      </section>
    </>
  );
};

export default Aboutus2;
