import React from 'react'
import { useEffect } from 'react'
import Navbar1 from '../components/Navbar1'
import Footer from '../components/Footer'
import Aboutus1 from '../components/Aboutus1'
import Aboutus2 from '../components/Aboutus2'

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return (
    <>
        <Navbar1/>
        <Aboutus1/>
        <Aboutus2/>
        <Footer/>
    </>
  )
}

export default Aboutus