import { Carousel } from 'react-carousel-minimal';
// import image1 from '../assets/hero.jpg'

const Carousel1=() => {
 const data = [
    {
      image: "https://images.unsplash.com/photo-1510114054347-3b2610d5e362?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aGF0fGVufDB8MHwwfHx8MA%3D%3D",
      // caption: "San Francisco"
    },
    {
      image: "https://images.unsplash.com/photo-1578998987066-88847474960c?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTEwfHxoYXR8ZW58MHwwfDB8fHww",
      // caption: "Scotland"
    },
    {
      image: "https://images.unsplash.com/photo-1510114054347-3b2610d5e362?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aGF0fGVufDB8MHwwfHx8MA%3D%3D",
      // caption: "Customize Hat"
    },
    {
      image: "https://images.unsplash.com/photo-1521369909029-2afed882baee?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aGF0fGVufDB8MHwwfHx8MA%3D%3D",
      // caption: "San Francisco"
    },
    {
      image: "https://images.unsplash.com/photo-1575428652377-a2d80e2277fc?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aGF0fGVufDB8MHwwfHx8MA%3D%3D",
      // caption: "Scotland"
     }
    
  ];

  const captionStyle = {
    fontSize: '4em',
    fontWeight: 'bold',
    color: "black"
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App bg-zinc-300  ">
      <div style={{}} className=' ' >
       <div style={{
          padding: ""
        }} className="  ">
          <Carousel 
            data={data}
            time={2000}
            width="1900px"
            height="530px"
            captionStyle={captionStyle}
            radius="0px"
            slideNumber={false}
            slideNumberStyle={slideNumberStyle}
            captionPosition="center"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="lightblue"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="130px"
            style={{
              textAlign: "center",
              maxWidth: "100%",
              maxHeight: "400px",
              marginTop: "0px",
              // marginBottom: "-80px"
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Carousel1;
