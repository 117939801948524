import React from "react";
import img1 from "../assets/slide1-1.jpg"
import img2 from "../assets/slide1-2.jpg"
import img3 from "../assets/slide1-3.jpg"
import img4 from "../assets/slide1-4.jpg"
import img5 from "../assets/slide1-5.jpg"
import img6 from "../assets/slide1-6.jpg"
import img7 from "../assets/slide1-7.jpg"
import img8 from "../assets/slide1-8.jpg"
import img9 from "../assets/slide1-9.jpg"
import img10 from "../assets/slide1-10.jpg"
import img11 from "../assets/slide2-1.jpg"
import img12 from "../assets/slide2-2.jpg"
import img13 from "../assets/slide2-3.jpg"
import img14 from "../assets/slide2-4.jpg"
import img15 from "../assets/slide2-5.jpg"
import img16 from "../assets/slide2-6.jpg"
import img17 from "../assets/slide2-7.jpg"
import img18 from "../assets/slide2-8.jpg"
import img19 from "../assets/slide2-9.jpg"
import img20 from "../assets/slide2-10.jpg"
import img21 from "../assets/slide3-1.jpg";
import img22 from "../assets/slide3-2.jpg";
import img23 from "../assets/slide3-3.jpg";
import img24 from "../assets/slide3-4.jpg";
import img25 from "../assets/slide3-5.jpg";
import img26 from "../assets/slide3-6.jpg";
import img27 from "../assets/slide3-7.jpg";
import img28 from "../assets/slide3-8.jpg";
import img29 from "../assets/slide3-9.jpg";
import img30 from "../assets/slide3-10.jpg";

const Products1 = () => {
  return (
    <>
      <section>
        <div className="flex flex-col justify-center items-center gap-7 text-center  bg-zinc-300 pb-24 pt-14 ">
          <h1 className="font-bold text-4xl px-16">SAMPLE PRODUCTS </h1>
          <p className="flex justify-center text-center items-center text-gray-700 px-10">
            At NewEra, we take pride in offering an extensive range of
            high-quality and customizable caps, hats, and t-shirts. Our products
            are designed to help you express your unique style, brand your
            business, commemorate special events, and more. Here's a glimpse of
            what we have to offer:
          </p>
        </div>
        <p className="text-2xl texl-bg-gray-700 text-center bg-zinc-100 ">
          Swipe<br />
          to see more ....       </p>
        <div className="sm:flex-row gap-10 items-center justify-center flex flex-col bg-zinc-300 py-12">
          <div className="carousel rounded-box sm:w-80 sm:h-92 w-[22rem] h-[33rem] ">
            <div className="carousel-item w-1/2">
              <img src={img1} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img2} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img3} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img4} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img5} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img  src={img6} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img  src={img7} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img  src={img8} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img  src={img9} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img  src={img10} className="w-full" />
            </div>
          </div>
          <div className="carousel rounded-box sm:w-80 sm:h-92 w-[22rem] h-[33rem]">
            <div className="carousel-item w-1/2">
              <img src={img11} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img12} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img13} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img14} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img15} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img src={img16} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img src={img17} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img src={img18} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img src={img19} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
            <img src={img20} className="w-full" />
            </div>
          </div>
          <div className="carousel rounded-box sm:w-80 sm:h-92 w-[22rem] h-[33rem]">
            <div className="carousel-item w-1/2">
              <img src={img21} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img22} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img23} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img24} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img25} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img26} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img27} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img28} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img29} className="w-full" />
            </div>
            <div className="carousel-item w-1/2">
              <img src={img30} className="w-full" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products1;
